import React, {useContext} from "react"
import CardList from "./CardList"
import Form from "./Form"
import { ThemeContextConsumer } from "./context"

function App() {

    return (
      <ThemeContextConsumer>
        {context => (
          <>
          <div onClick={context.navClosed ? context.openNav : context.closeNav} id="navigation">
            <div className="hamburger"></div>
            <div className="hamburger"></div>
            <div className="hamburger"></div>
          </div>
          <div id="mySidenav" class="sidenav">
            <a href="https://www.tylergthomas.com">Home</a>
            <a href="https://artic.tylergthomas.com">ArtIC Digital Gallery</a>
            <a href="https://blog-props.tylergthomas.com">Blog Props</a>
            <a href="https://ugly-things.tylergthomas.com">Ugly Things Forum</a>
            <a href="https://tropicalfav.tylergthomas.com">Tropical Fruits and Veggies</a>
            <a href="https://meme-generator.tylergthomas.com">Meme Generator</a>
            <a href="https://name-badge.tylergthomas.com">Name Badge Creator</a>
          </div>
          <div id="container">
            <Form />
            <CardList />
          </div>
          </>
        )}
        
      </ThemeContextConsumer>
      
    )
}

export default App;